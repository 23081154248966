"use client"

import {useEffect} from "react"
import {useRouter, useSearchParams} from "next/navigation"

// Define the Everflow window interface (same as in EverflowTracker)
declare global {
    interface Window {
        EF: {
            click: (params: any) => void
            conversion: (params: any) => void
            impression: (params: any) => void
            configure: (params: any) => void
            urlParameter: (name: string) => string | null
        }
    }
}

export default function EverflowAuthTracker() {
    const searchParams = useSearchParams()
    const router = useRouter()

    useEffect(() => {
        // Extract tracking parameters from URL
        const transactionId = searchParams.get("ef_tid")
        const affiliateId = searchParams.get("ef_aid")
        const offerId = searchParams.get("of_id") || "1" // Default to offer ID 1 if not specified
        const source = searchParams.get("source") || "direct" // Track traffic source if available

        // Store in localStorage for persistence across the auth process
        if (transactionId) {
            localStorage.setItem("ef_transaction_id", transactionId)
            console.log("[Everflow] Auth page: Stored transaction_id:", transactionId)
        }

        if (affiliateId) {
            localStorage.setItem("ef_affiliate_id", affiliateId)
            console.log("[Everflow] Auth page: Stored affiliate_id:", affiliateId)
        }

        if (offerId) {
            localStorage.setItem("ef_offer_id", offerId)
            console.log("[Everflow] Auth page: Stored offer_id:", offerId)
        }

        // Store the current URL path to track which auth page user is on
        const currentPath = window.location.pathname
        localStorage.setItem("ef_landing_page", currentPath)

        // Initialize Everflow tracking if parameters exist
        const initializeEverflow = () => {
            try {
                if (typeof window !== "undefined" && window.EF && (transactionId || affiliateId)) {
                    console.log("[Everflow] Auth page: Initializing tracking")

                    // Track impression first for better funnel visibility
                    window.EF.impression({
                        offer_id: parseInt(offerId) || 1,
                        affiliate_id: affiliateId || null,
                        sub1: "auth_page",
                        sub2: currentPath // Which auth page
                    })

                    // Initialize click tracking
                    window.EF.click({
                        offer_id: parseInt(offerId) || 1, // Use passed offer ID or default
                        affiliate_id: affiliateId || null,
                        transaction_id: transactionId || undefined,
                        sub1: "auth_page",
                        sub2: currentPath, // Track which auth page
                        sub3: "signup_intent", // Flag this as a signup intent
                        sub4: source // Track traffic source
                    })

                    console.log("[Everflow] Auth page: Initialized tracking")
                } else if (typeof window !== "undefined" && !window.EF && (transactionId || affiliateId)) {
                    // If EF not available yet but we have parameters, retry
                    setTimeout(initializeEverflow, 500)
                }
            } catch (error) {
                console.error("[Everflow] Auth page: Error initializing tracking:", error)
            }
        }

        // Run initialization after a short delay to ensure script is loaded
        const timer = setTimeout(initializeEverflow, 500)

        return () => clearTimeout(timer)
    }, [searchParams])

    return null // This component doesn't render anything
}
